import Head from 'next/head';
import { translations, useLocale } from '@lilbit/shared';
import Link from 'next/link';
import { useRouter } from 'next/router';

const NotFound = ({ locale = 'en' }: { locale: string }) => {
  const router = useRouter();
  const urlPrefix = useLocale().urlPrefix;
  // useEffect(() => {
  //   if (urlPrefix === 'en' || urlPrefix === 'no') {
  //     router.push(`/${urlPrefix}/app`);
  //   } else {
  //     router.push('/no/app');
  //   }
  // }, [urlPrefix]);

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-32 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-primary-2 sm:text-5xl">
              404
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  {translations.not_found.page_not_found[locale]}
                </h1>
                <p className="mt-1 text-base text-gray-500">
                  {translations.not_found.check_address[locale]}
                </p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link href="/">
                  <a className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-2 hover:bg-primary-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    {translations.not_found.back_home[locale]}
                  </a>
                </Link>

                <a
                  href={`https://lildog.com/${
                    locale === 'no' ? 'no' : 'en'
                  }/support`}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-primary-2 bg-primary-4 hover:bg-primary-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {translations.not_found.contact_support[locale]}
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default NotFound;
