import {
  getSiteConfig,
  getPageSlugs,
  getWoocommerceProducts,
  getLildogProductPrices,
  getFooter,
  getTaxRate,
  getStores,
} from '../../../lib/api';
import {
  locales,
  defaultCategoryLocales,
  Layout,
  getValidLocale,
  localize,
  indexPageQuery,
  GlobalConfig,
  Content,
  LocaleType,
  Product,
  Image as ImageType,
  LildogProductPrices,
  BlogPostProps,
  SearchBar,
  Store,
  newIndexPageQuery,
  Footerv2Props,
} from '@lilbit/shared';
import RenderSections from '../../../components/RenderSections';
import { getClient } from '../../../lib/sanity.server';
import { urlFor } from '../../../lib/sanity';
import NotFound from '../../404';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { ZendeskAPI } from 'react-zendesk';
import { upperFirst } from 'lodash';
import SimpleBlockContent from '../../../components/SimpleBlockContent';
import Head from 'next/head';

interface Props {
  config: GlobalConfig;
  content: Array<Content & { _ref: string; blogPost: BlogPostProps }>;
  locale: LocaleType;
  products: Product[];
  description: string;
  meta_image: ImageType;
  mainProduct: Product;
  mainProductPrices: LildogProductPrices;
  footer: Footerv2Props;
  standardTaxRate: number;
  productTaxRate: number;
  requiresAuth: boolean | undefined;
  stores: Store[];
}
const LandingPage = (props: Props) => {
  const {
    config,
    content,
    locale,
    products,
    description = '',
    meta_image,
    mainProduct,
    mainProductPrices,
    footer,
    standardTaxRate,
    productTaxRate,
    requiresAuth,
    stores,
    // eslint-disable-next-line no-unused-vars
    //title = 'Missing title',
  } = props;
  const router = useRouter();
  const isApp = router.query.app || false;

  //add simpleblockcontent to footer
  footer.SimpleBlockContent = SimpleBlockContent;

  useEffect(() => {
    if (!isApp) {
      setTimeout(() => {
        ZendeskAPI('webWidget', 'show', 'setLocale', locale?.urlPrefix);
      }, 1000);
    } else {
      setTimeout(() => {
        ZendeskAPI('webWidget', 'hide');
      }, 1000);
    }
    return () => {
      ZendeskAPI('webWidget', 'hide');
    };
  }, [locale, isApp]);

  if (!config) {
    return <NotFound locale={locale?.urlPrefix} />;
  }
  const inDogbreeds = ['dogbreeds', 'hunderaser'].includes(
    !(router.query.category instanceof Array) && router.query.category
  );
  const inNews = ['news', 'nyheter'].includes(
    !(router.query.category instanceof Array) && router.query.category
  );

  if (isApp)
    return (
      <div className="px-4">
        <RenderSections
          sections={content}
          locale={locale}
          products={products}
          mainProductPrices={mainProductPrices}
          isApp={true}
          mainProduct={mainProduct}
          requiresAuth={requiresAuth}
        />
      </div>
    );
  return (
    <Layout
      footer={footer}
      config={config}
      metadata={description}
      title={'Lildog | ' + upperFirst(router.query.category as string)}
      user={''}
      imageMetadata={meta_image ? urlFor(meta_image).url() : ''}
      setMarginX={false}
      urlFor={urlFor}
      siteName="lildog"
      mainProduct={mainProduct}
    >
      {(inDogbreeds || inNews) && <SearchBar content={content} />}
      {content && (
        <RenderSections
          sections={content}
          locale={locale}
          products={products}
          mainProductPrices={mainProductPrices}
          mainProduct={mainProduct}
          standardTaxRate={standardTaxRate}
          productTaxRate={productTaxRate}
          requiresAuth={requiresAuth}
          stores={stores}
          config={config}
        />
      )}
      {!content && <NotFound locale={locale?.urlPrefix} />}
    </Layout>
  );
};

export const getStaticProps = async ({ params, preview = false }) => {
  const locale = getValidLocale(params?.lang);
  const sanityLanguageIdentifier = locale.sanityLanguageIdentifier;
  const slug = params?.category;
  let response;
  let page;
  // Get news and dogbreeds from old dataset until they are migrated
  if (
    slug === 'dogbreeds' ||
    slug === 'hunderaser' ||
    slug === 'news' ||
    slug === 'nyheter'
  ) {
    response = await getClient(preview, false).fetch(indexPageQuery, {
      sanityLanguageIdentifier,
      slug,
    });
    page = response ? response.page : null;
    page = localize(page, [sanityLanguageIdentifier]);
  } else {
    response = await getClient(preview, true).fetch(newIndexPageQuery, {
      sanityLanguageIdentifier,
      slug,
    });
    page = response ? response.localizedPage : null;
  }
  const footerData = await getFooter();
  const footer = localize(footerData, [sanityLanguageIdentifier]);
  if (page) page.slug = slug;
  const products = await getWoocommerceProducts();
  const mainProduct = await getWoocommerceProducts(true);
  let mainProductPrices = await getLildogProductPrices();
  let config = await getSiteConfig(sanityLanguageIdentifier);
  const description = page?.description
    ? localize(page.description, [sanityLanguageIdentifier])
    : '';

  const standardTaxRate = await getTaxRate('standard');
  const productTaxRate =
    mainProduct.tax_class === ''
      ? standardTaxRate
      : await getTaxRate(mainProduct.tax_class);

  const stores = await getStores(sanityLanguageIdentifier);

  let slugsForAllLocales = await getPageSlugs();
  if (slugsForAllLocales && config) {
    const slugLocales = {};
    const groupid = slugsForAllLocales.filter((s) => s.slug.current === slug)[0]
      ?.groupid;
    slugsForAllLocales.forEach((item: any) => {
      if (item.groupid === groupid) {
        slugLocales[item.language] = item.slug;
      }
    });
    config.slugsForAllLocales = slugLocales;
  }
  if (config && defaultCategoryLocales)
    config.slugsForAllCategories = defaultCategoryLocales;
  // Temporary fix for undefined values in posts for locales that have not been translated yet
  page = JSON.parse(JSON.stringify(page));
  if (process.env.ENVIRONMENT === 'DEV') {
    return {
      props: {
        ...page,
        config,
        locale,
        products,
        footer,
        description,
        mainProduct,
        mainProductPrices,
        standardTaxRate,
        productTaxRate,
        stores,
      },
      revalidate: 60 * 60 * 24,
    };
  }

  return {
    props: {
      ...page,
      config,
      locale,
      products,
      footer,
      description,
      mainProduct,
      mainProductPrices,
      standardTaxRate,
      productTaxRate,
      stores,
    },
    revalidate: 60 * 60 * 24,
  };
};

export const getStaticPaths = async () => {
  const slugsArray = await getPageSlugs();
  let paths = localizePaths(slugsArray, locales);
  return {
    paths,
    fallback: 'blocking',
  };
};

const localizePaths = (slugs, locales) => {
  var localizedPaths = [];
  for (let locale of locales) {
    for (let slugContainer of slugs) {
      let lang = locale.sanityLanguageIdentifier;
      if (slugContainer.slug[locale.sanityLanguageIdentifier]) {
        const category =
          slugContainer.slug[locale.sanityLanguageIdentifier].current;
        localizedPaths.push({ params: { lang, category } });
      }
    }
  }
  return localizedPaths;
};

export default LandingPage;
